import { MDXProvider } from '@mdx-js/react';
import { MDXRenderer } from 'gatsby-plugin-mdx';
import React from 'react';
import styled, { css } from 'styled-components';

import { Button } from 'src/components/atoms/Button';
import MDXComponents from 'src/components/mdx-components';
import { InlineList } from 'src/components/molecules/InlineList';
import { Section } from 'src/components/molecules/Section';
import { Teaser } from 'src/components/molecules/Teaser';
import { FluidImg } from 'src/embeds/image/image-dato';

export default function Drawer({ heading, content, contentNode, callouts, ctas, customData }) {
  callouts = callouts.filter((callout) => callout.__typename == 'DatoCmsGridCard');
  customData = JSON.parse(customData);

  return (
    <MDXProvider components={MDXComponents}>
      <Section {...customData?.flags}>
        <h2>{heading}</h2>
        {content.length > 0 && <MDXRenderer>{contentNode.childMdx.body}</MDXRenderer>}
        {callouts.length > 0 ? (
          <Grid>
            {callouts.map(
              ({ heading, preHeading, contentNode, media, url, cta, cardLayout, headerFit }, i) => (
                <Column key={i} layout={cardLayout}>
                  <Teaser
                    header={
                      !!media && (
                        <CardImage
                          maxWidth={0}
                          data={media}
                          pictureStyle={{ objectFit: headerFit === 'full' ? 'cover' : 'contain' }}
                        />
                      )
                    }
                    title={heading}
                    subtitle={preHeading}
                    footer={!!cta && `${cta} →`}
                    to={url}
                    layout={cardLayout}
                    headerFit={headerFit}
                    forceLayout={i === callouts.length - 1}
                  >
                    {!!contentNode.childMdx.body && <MDXRenderer>{contentNode.childMdx.body}</MDXRenderer>}
                  </Teaser>
                </Column>
              ),
            )}
          </Grid>
        ) : null}
        {ctas.length > 0 && (
          <InlineList centered style={{ width: '100%', paddingTop: 80 }}>
            {ctas.map((cta, i) => {
              const ctaFlags = i > 0 ? { secondary: true } : {};
              return (
                <li key={i}>
                  <Button to={cta.url} {...ctaFlags}>
                    {cta.text}
                  </Button>
                </li>
              );
            })}
          </InlineList>
        )}
      </Section>
    </MDXProvider>
  );
}

const CardImage = styled(FluidImg)`
  width: 100%;
  min-height: 100%;
`;

const Grid = styled.div`
  text-align: left;
  display: grid;
  grid-gap: 2rem;

  @media (min-width: ${({ theme }) => theme.xsmallBreakpoint}) {
    grid-auto-flow: dense;
    grid-template-columns: repeat(2, 1fr);
  }

  @media (min-width: ${({ theme }) => theme.smallBreakpoint}) {
    grid-template-columns: repeat(5, 1fr);
  }
`;

const Column = styled.div`
  @media (min-width: ${({ theme }) => theme.xsmallBreakpoint}) and (max-width: calc(${({ theme }) =>
      theme.smallBreakpoint} - 1px)) {
    &:last-child {
      grid-column: span 2;
    }
  }

  @media (min-width: ${({ theme }) => theme.smallBreakpoint}) {
    ${({ layout = 'vertical' }) =>
      layout === 'vertical'
        ? css`
            grid-column: span 2;
            grid-row: span 2;
          `
        : css`
            grid-column: span 3;
          `}
  }
`;
